var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.recoveryLinkSent
      ? _c(
          "div",
          [
            _c("ErrorAlert", {
              attrs: { errorMessage: _vm.recoveryErrorMessage },
            }),
            _c(
              "v-tabs",
              {
                attrs: { grow: "" },
                model: {
                  value: _vm.recoveryTabModel,
                  callback: function ($$v) {
                    _vm.recoveryTabModel = $$v
                  },
                  expression: "recoveryTabModel",
                },
              },
              [
                _c("v-tab", [
                  _c("span", { staticClass: "gotham-medium-font" }, [
                    _vm._v("RESET YOUR PASSWORD"),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.recoveryTabModel,
                  callback: function ($$v) {
                    _vm.recoveryTabModel = $$v
                  },
                  expression: "recoveryTabModel",
                },
              },
              [
                _c(
                  "v-tab-item",
                  [
                    _c(
                      "v-form",
                      {
                        model: {
                          value: _vm.formValid,
                          callback: function ($$v) {
                            _vm.formValid = $$v
                          },
                          expression: "formValid",
                        },
                      },
                      [
                        _c("v-text-field", {
                          attrs: {
                            rules: _vm.emailRules,
                            label: "Please enter your email",
                            height: "56",
                            "hide-details": "",
                            "append-icon": "mdi-check",
                            required: "",
                          },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mt-10",
                            attrs: {
                              disabled: !_vm.formValid,
                              color: "primary",
                              width: "100%",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sendRecoveryLink()
                              },
                            },
                          },
                          [_c("strong", [_vm._v("SEND RECOVERY LINK")])]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "d-flex justify-center" },
          [
            _c("SuccessMessage", {
              attrs: { successMessage: _vm.recoverySuccessMessage },
            }),
          ],
          1
        ),
    _c(
      "p",
      { staticClass: "font-16 gotham-light-font text-center mt-10" },
      [
        _c(
          "router-link",
          { staticClass: "primary--text", attrs: { to: "/login" } },
          [_vm._v("Go back to login")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }