




































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import ErrorAlert from '../Utility/ErrorAlert.vue';
import SuccessMessage from '../Utility/SuccessMessage.vue';
import { SignUpService } from '@services/signup.service';

export default Vue.extend({
    name: 'Recovery',
    components: {
        ErrorAlert,
        SuccessMessage
    },
    data() {
        return {
            recoveryLinkSent: false,
            recoveryTabModel: null,
            formValid: false,
            emailRules: [
                (v) => !!v || 'Email is required',
                (v) =>
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        v
                    ) || 'Email must be valid',
                (v) => !/\s/.test(v) || 'Email cannot contain spaces',
            ],
            email: '',
            recoveryErrorMessage: '',
            recoverySuccessMessage: 'A password recovery link was sent to your email.'
        };
    },
    props: {

    },
    methods: {
        async sendRecoveryLink() {
            this.fullscreenLoader = this.$loading.show();

            try {
                const recoveryResponse = await SignUpService.sendRecoveryLink({ id: 'UserID', email: this.email.toLowerCase().trim() })

                this.fullscreenLoader.hide();
                if (recoveryResponse.code === 0) return this.recoveryLinkSent = true;
                this._showNonRecoverableError()
            } catch (error) {
                this.fullscreenLoader.hide();
                this._showNonRecoverableError();
            }
        },
        _showNonRecoverableError() {
            this.recoveryErrorMessage = 'Unable to send recovey link. Please try again or contact support@searchadsmaven.com if issue persists';
        }
    },
});
